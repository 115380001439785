import React from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  let [loginToken, setLoginToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let [project_ids, setProjectids] = useState([]);
  let [device_ids, setDeviceids] = useState([]);
  let [imeilist, setImeilist] = useState([]);
  let [location_list, setLocationList] = useState([]);
  let [namelist, setNamelist] = useState([]);
  let [headers, setHeaders] = useState({});
  let [token, setToken] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post("https://srt.smart-iot.in/api/super-admin/login", {
        username: username,
        password: password,
        remember: true,
        domain: "srt",
      })
      .then(function (response) {
        console.log(response.data.message);
        // console.log(response.data.data);
        token = response.data.data;
        loginToken = response.data.data;
        loginToken = localStorage.setItem(
          "loginToken",
          JSON.stringify(loginToken)
        );
        console.log(localStorage.getItem("loginToken"));
        setLoginToken(loginToken);
        console.log(token);
        setToken(token);
        headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        console.log(headers);
        setHeaders(headers);

        axios
          .get(
            "https://srt.smart-iot.in/api/super-admin/project/getallprojects/5",
            { headers }
          )
          .then(function (response) {
            console.log(response.data);
            console.log(response.data.data.projects[0].id);
            let x = response.data.data.projects;
            console.log(x);
            let loop = x.length;
            console.log(loop);
            project_ids = [];
            for (let i = 0; i < loop; i++) {
              project_ids[i] = x[i].id;
            }
            console.log(project_ids);
            setProjectids(project_ids);

            axios
              .get(
                `https://srt.smart-iot.in/api/super-admin/project/getallprojectdevices/5/${project_ids[0]}`,
                { headers }
              )
              .then(function (response) {
                console.log(response.data);
                console.log(response.data.data.devices[0].imei);
                console.log(response.data.data.devices[0].location);
                let y = response.data.data.devices;
                console.log(y.length);
                for (let i = 0; i < y.length; i++) {
                  imeilist[i] = y[i].imei;
                  device_ids[i] = y[i].id;
                  location_list[i] = y[i].location;
                  namelist[i] = y[i].name;
                }
                console.log(imeilist);
                console.log(device_ids);
                console.log(location_list);
                setDeviceids(device_ids);
                setImeilist(imeilist);
                setLocationList(location_list);
                setNamelist(namelist);
                if (imeilist) {
                  localStorage.setItem("imeilist", JSON.stringify(imeilist));
                }
                if (device_ids) {
                  localStorage.setItem(
                    "device_ids",
                    JSON.stringify(device_ids)
                  );
                }
                if (location_list) {
                  localStorage.setItem(
                    "location_list",
                    JSON.stringify(location_list)
                  );
                }
                if (namelist) {
                  localStorage.setItem("namelist", JSON.stringify(namelist));
                }

                navigate({
                  pathname: "/Home",
                  state: { loginToken: loginToken },
                });
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="">
      <div className="bg-gray-50 min-h-screen flex items-center justify-center p-2">
        <div className="bg-[#F5E393] flex rounded-2xl shadow-gray-500 shadow-lg max-w-5xl p-5">
          {/* form */}
          <div className="sm:w-1/2 px-16 ">
            <h2 className="font-bold text-3xl text-[#e68d26]">Login.</h2>
            <p className="text-sm mt-4 text-[#e68d26]">
              If you already a member,easily log in
            </p>
            <hr className="border-[#e68d26] mt-4 " />
            <hr className="border-[#e68d26]  " />
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <input
                type="text"
                name="username"
                className="p-2 mt-8 rounded-xl border-gray-400"
                placeholder="Enter Your username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="p-2 mt-8 rounded-xl border-gray-400 w-full"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  class="bi bi-eye absolute top-1/2 right-3 translate-y-1/2 cursor-pointer "
                  viewBox="0 0 16 16"
                  onClick={togglePasswordVisibility}
                >
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              </div>
              <button
                type="submit"
                className="bg-[#F8B619] rounded-xl py-2 mt-2"
              >
                Login
              </button>
            </form>
          </div>
          {/* image */}
          <div className="sm:block hidden w-1/2 ">
            <img
              //   src="../images/GENSET.png"
              src="/images/GENSET.png"
              alt="Login"
              className="rounded-2xl  shadow-gray-600  shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
