import React from "react";

function WelcomeBanner() {
  return (
    <div className="relative p-4 overflow-hidden  rounded-sm m sm:p-6">
      {/* Content */}
      <div className="relative">
        <h1 className="mb-1 text-2xl font-bold text-black md:text-3xl bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-xs p-4 text-center font-sans">
          REMOTE GENSET MONITORING SYSTEM(RGMS)
        </h1>
        <p className="text-gray-500 text-center text-2xl font-poppins font-sans ">
          Data Analytics - Real Time Parameters
        </p>
      </div>

      {/* //glassmorphism effect example */}
      {/* <div class="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-4">
        <div class="bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg shadow-md p-4">
          <div class="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg rounded-lg shadow-sm p-4">
            <div class="bg-white bg-opacity-60 backdrop-filter backdrop-blur-lg rounded-lg shadow-xs p-4">
              4G PUMP HEALTH MONITORING SYSTEM 👋
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default WelcomeBanner;
