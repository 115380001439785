import "./App.css";
import Dashboard from "./Dashboard";
import Home from "./Home";
import LoginPage from "./LoginPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {/* <LoginPage /> */}
      <Routes>
        {/* <Route exact path="/" element={<Barchart />} /> */}
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/Home" element={<Home />} />
        <Route exact path="/Dashboard" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
